/*
    POINT D'ENTRÉE BRIKS FONTS
    Placer le chemin du fichier dans le fichier _config.yml de LibDoc
*/

/*
    CORE
    Fonctionnalités principales 
    Inclure briks/_core.scss pour obtenir les fonctions helpers et les mixins.
    Aucune déclaration CSS n'est générée à partir de ce fichier.
*/
@import 'briks/core';
/*
    DESIGN TOKENS
    Fichier SASS contenant les design tokens du projet
*/
@import 'briks/settings/tokens/ucar';
/*
    FONTS
    Requiert briks/_core.scss
    Génère les déclarations CSS nécéssaires pour l'usage des typos
*/
@import 'briks/fonts';
